<script setup>
import TextField from "@/components/common/TextField.vue"
import { useUserStore } from "@/stores/User"
import useVuelidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { defineProps, onMounted, ref, computed } from "vue"

const props = defineProps({
  formData: {
    type: Object,
  },
  edit: {
    type: Boolean,
    default: false,
  },
});

const userStore = useUserStore();
const handlerValidate = useVuelidate(
  {
    title: {
      required,
    },
  }
  //   store.currentProduct
);

const dataForm = ref({
  name: null,
  fname: null,
  email: null,
  phone: null,
});

onMounted(() => {
  if (props.formData)
    dataForm.value = {
      name: props.formData?.given_name || userStore.user?.name,
      fname: props.formData?.family_name || userStore.user?.fname,
      email: props.formData?.email || userStore.user?.email,
      phone: props.formData?.phone || userStore.user?.phone
    };

  sessionStorage.setItem("profile", JSON.stringify(dataForm.value));
});

const phone = computed({
  get: () => props.formData?.phone,
  set: (v) => userStore.profile.user.phone = v
})

const first_name = computed({
  get: () => props.formData?.given_name,
  set: (v) => userStore._user.name = v
})

const last_name = computed({
  get: () => props.formData?.family_name,
  set: (v) => userStore._user.fname = v
})

</script>
<template>
  <div class="form-group max-w-md">
    <div class="d-flex gap-4 mb-2">
      <TextField
        isDisabled
        id="name"
        isRequerid
        v-model="first_name"
        :errorMessage="
          handlerValidate?.['name']?.$errors?.length > 0
            ? 'Este campo es requerido'
            : undefined
        "
        name="name"
        :label="$t('PROFILE.NAME')"
        class="mb-2"
      />
      <TextField
        isRequerid
        id="fname"
        v-model="last_name"
        :errorMessage="
          handlerValidate?.['fname']?.$errors?.length > 0
            ? 'Este campo es requerido'
            : undefined
        "
        name="fname"
        :label="$t('PROFILE.LASTNAME')"
        class="mb-2"
        isDisabled
      />
    </div>
    <TextField
      isRequerid
      isDisabled
      v-model="phone"
      id="email"
      class="mb-2"
      :errorMessage="
        handlerValidate?.['email']?.$errors?.length > 0
          ? 'Este campo es requerido'
          : undefined
      "
      name="email"
      :label="$t('PROFILE.PHONE')"
    />
    <TextField
      isRequerid
      isDisabled
      v-model="dataForm.email"
      id="email"
      class="mb-2"
      :errorMessage="
        handlerValidate?.['email']?.$errors?.length > 0
          ? 'Este campo es requerido'
          : undefined
      "
      name="email"
      :label="$t('PROFILE.EMAIL')"
    />
  </div>
</template>
