<script setup>
import TextField from "@/components/common/TextField.vue"
import { useCountryStore } from "@/stores/Countrys"
import { useUserStore } from "@/stores/User"
import useVuelidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { computed, defineProps, onMounted, ref } from "vue"

const props = defineProps({
  formData: {
    type: Object,
  },
  edit: {
    type: Boolean,
    default: false,
  },
});

const userStore = useUserStore();
const countryStore = useCountryStore();
const handlerValidate = useVuelidate(
  {
    title: {
      required,
    },
  }
  //   store.currentProduct
);

const dataForm = ref({
  city: null,

  name_company: userStore.producer?.company_name,
  nature: 0,
  registration_number: null,
  tax_id_number: null,
  vax_id_number: null,
});

onMounted(async () => {
  if (props.formData)
    dataForm.value = {
      // name_company: userStore.producer?.company_name,
      nature: userStore.producer?.entity_type,
      registration_number: userStore.producer?.registration_number,
      tax_id_number: userStore.producer?.tax_id_number,
      vax_id_number: userStore.producer?.vax_id_number,
    };
  await countryStore.getCountry();
});

const paymentMethod = ref(["payoneer", "paypal"]);

const company_name = computed({
  get: () => userStore.producer?.company_name,
  set: (v) => (userStore._producer.company_name = v),
});

const nature = computed({
  get: () => userStore.producer?.entity_type,
  set: (v) => (userStore._producer.entity_type = v),
});

const registration_number = computed({
  get: () => userStore.producer?.registration_number,
  set: (v) => (userStore._producer.registration_number = v),
});

const tax_id_number = computed({
  get: () => userStore.producer?.tax_id_number,
  set: (v) => (userStore._producer.tax_id_number = v),
});

const vat_id_number = computed({
  get: () => userStore.producer?.vat_id_number,
  set: (v) => (userStore._producer.vat_id_number = v),
});

const city = computed({
  get: () => userStore.profile?.city,
  set: (v) => (userStore._profile.city = v),
});

const address = computed({
  get: () => userStore.profile?.address,
  set: (v) => (userStore._profile.address = v),
});

const country = computed({
  get: () => userStore.profile?.country,
  set: (v) => (userStore._profile.country = v),
});

const state = computed({
  get: () => userStore.profile?.state,
  set: (v) => (userStore._profile.state = v),
});

const zip_code = computed({
  get: () => userStore.profile?.zip_code,
  set: (v) => (userStore._profile.zip_code = v),
});

const apartment = computed({
  get: () => userStore.profile?.apartment,
  set: (v) => (userStore._profile.apartment = v),
});

const payload_email = computed({
  get: () => userStore.profile?.payload_email,
  set: (v) => (userStore._profile.payload_email = v),
});

const account_type = computed({
  get: () => userStore.profile?.account_type,
  set: (v) => (userStore._profile.account_type = v),
});
</script>
<template>
  <div class="main-title mt-4">
    <h2>{{ $t("PROFILE.COMPANY_INFO") }}</h2>
  </div>
  <section class="bg-sections mb-2 w-100">
    <div class="form-group w-50">
      <TextField
        :isDisabled="!edit"
        id="companyName"
        isRequerid
        v-model="company_name"
        :errorMessage="
          handlerValidate?.['name']?.$errors?.length > 0
            ? 'Este campo es requerido'
            : undefined
        "
        name="companyName"
        :label="$t('COMPANY.NAME')"
        class="mb-2"
      />

      <div class="textField mb-2">
        <span v-text="$t('COMPANY.NATURE')" />
        <div class="d-flex gap-2">
          <div class="radio-style">
            <input
              v-model="nature"
              type="radio"
              id="radio-button-0"
              name="radio-button"
              value="juridica"
            />
            <label for="radio-button-0">
              <span class="frb-description">{{
                $t("COMPANY.LEGAL_PERSON")
              }}</span>
            </label>
          </div>
          <div class="radio-style">
            <input
              v-model="nature"
              type="radio"
              id="radio-button-1"
              name="radio-button"
              value="fisica"
            />
            <label for="radio-button-1">
              <span class="frb-description"
                >{{ $t("COMPANY.NATURAL_PERSON") }}.</span
              >
            </label>
          </div>
        </div>
      </div>

      <TextField
        isRequerid
        :hasSpinners="false"
        id="registrationNumber"
        type="number"
        v-model="registration_number"
        :errorMessage="
          handlerValidate?.['fname']?.$errors?.length > 0
            ? 'Este campo es requerido'
            : undefined
        "
        name="registrationNumber"
        :label="$t('COMPANY.REGISTER_NUMBER')"
        class="mb-2"
        :isDisabled="!edit"
      />
      <!-- <TextField
        isRequerid
        :isDisabled="!edit"
        v-model="dataForm.legal_nature"
        id="email"
        class="mb-2"
        :errorMessage="
          handlerValidate?.['email']?.$errors?.length > 0
            ? 'Este campo es requerido'
            : undefined
        "
        name="email"
        :label="$t('COMPANY.LEGAL_NATURE')"
      /> -->
      <!-- <TextField
        isRequerid
        :isDisabled="!edit"
        v-model="dataForm.affiliateParameter "
        id=" Affiliate Parameter "
        class="mb-2"
        :errorMessage="
          handlerValidate?.['affiliateParameter ']?.$errors?.length > 0
            ? 'Este campo es requerido'
            : undefined
        "
        name=" Affiliate Parameter "
        :label="$t('COMPANY.PARAMETER_AFFILIATION')"
      /> -->
    </div>
  </section>

  <div class="main-title mt-4">
    <h2>{{ $t("PROFILE.TAX_INFO") }}</h2>
  </div>
  <section class="bg-sections w-100">
    <div class="d-flex gap-4 w-50">
      <TextField
        isRequerid
        id="tax_id"
        type="number"
        :hasSpinners="false"
        v-model="tax_id_number"
        :errorMessage="
          handlerValidate?.['fname']?.$errors?.length > 0
            ? 'Este campo es requerido'
            : undefined
        "
        name="tax"
        :label="$t('COMPANY.TAX_NUMBER')"
        class="mb-2"
        :isDisabled="!edit"
      />
      <TextField
        isRequerid
        :isDisabled="!edit"
        v-model="vat_id_number"
        id="var_id"
        class="mb-2"
        :errorMessage="
          handlerValidate?.['email']?.$errors?.length > 0
            ? 'Este campo es requerido'
            : undefined
        "
        name="vax"
        :label="$t('COMPANY.VAT_NUMBER')"
      />
    </div>
    <div class="form-group w-50">
      <TextField
        isRequerid
        :isDisabled="!edit"
        v-model="payload_email"
        id="var_id"
        class="mb-2 w-full"
        name="vax"
        :label="'Email para pagos'"
      />
      <div class="form-group mt-2">
        <label
          class="w-full font-medium text-background-dark textField"
          for="productCategory"
          >Tipo de pago</label
        >
        <select
          :disabled="!edit"
          class="form-control"
          id="productCategory"
          v-model="account_type"
          required
        >
          <option v-for="item in paymentMethod" :key="item" :value="item">
            {{ item }}
          </option>
        </select>
      </div>
    </div>
  </section>
  <div class="main-title mt-4">
    <h2>{{ $t("COMPANY.ADDRESS") }}</h2>
  </div>
  <section class="bg-sections w-100">
    <div class="d-flex gap-4">
      <div class="form-group w-100 ">
        <label for="countries" class="textField"
          >Pais
        </label>
        <select
        class="form-control w-100"
        :disabled="!edit"
          id="countries"
          v-model="country"
        >
          <option
            v-for="country in countryStore.countries"
            :key="country"
            :value="country.name"
          >
            {{ country.name }}
          </option>
        </select>
      </div>
      <!-- <TextField
      isRequerid
      id="country"
      type="text"
      v-model="country"
      :errorMessage="
        handlerValidate?.['fname']?.$errors?.length > 0
          ? 'Este campo es requerido'
          : undefined
      "
      name="fname"
      :label="$t('COMPANY.COUNTRY')"
      class="mb-2"
      :isDisabled="!edit"
    /> -->
      <TextField
        isRequerid
        :isDisabled="!edit"
        v-model="address"
        id="email"
        class="mb-2"
        :errorMessage="
          handlerValidate?.['email']?.$errors?.length > 0
            ? 'Este campo es requerido'
            : undefined
        "
        name="email"
        :label="$t('COMPANY.ADDRESS')"
      />

      <TextField
        isRequerid
        :isDisabled="!edit"
        v-model="apartment"
        id="email"
        class="mb-2"
        :errorMessage="
          handlerValidate?.['email']?.$errors?.length > 0
            ? 'Este campo es requerido'
            : undefined
        "
        name="email"
        :label="$t('COMPANY.APARTMENT')"
      />
    </div>
    <div class="d-flex gap-4">
      <TextField
        isRequerid
        :isDisabled="!edit"
        v-model="city"
        id="email"
        class="mb-2"
        :errorMessage="
          handlerValidate?.['email']?.$errors?.length > 0
            ? 'Este campo es requerido'
            : undefined
        "
        name="email"
        :label="$t('COMPANY.CITY')"
      />
      <TextField
        isRequerid
        :isDisabled="!edit"
        v-model="state"
        id="email"
        class="mb-2"
        :errorMessage="
          handlerValidate?.['email']?.$errors?.length > 0
            ? 'Este campo es requerido'
            : undefined
        "
        name="email"
        :label="$t('COMPANY.STATE')"
      />
      <TextField
        isRequerid
        :isDisabled="!edit"
        v-model="zip_code"
        id="email"
        class="mb-2"
        :errorMessage="
          handlerValidate?.['email']?.$errors?.length > 0
            ? 'Este campo es requerido'
            : undefined
        "
        name="email"
        :label="$t('COMPANY.POSTAL_CODE')"
      />
    </div>
  </section>
</template>
<style scoped>
.textField {
  position: relative;
  width: 100%;
}

.textField {
  font-weight: 500;
}
</style>
