<template>
  <div class="">
    <div class="main-title">
      <h2 class="section-title">Productos</h2>
      <router-link to="/addProduct" class="btn btn-dark">
        <i class="fas fa-plus"></i>
        {{ $t("PRODUCT.ADD_PRODUCT") }}
      </router-link>
    </div>

    <div class="row w-100 pb-4">
      <div class="col-9 col-md-4">
        <TextField
          v-model="productName"
          id="producerName"
          type="text"
          name="producerName"
          :label="'Buscar Producto'"
          placeholder="Buscar "
          @keyup.enter="search()"
        />
      </div>
      <div class="col-3 col-md-2">
        <div class="d-flex gap-4 justify-content-between items-center">
          <div class="w-100">
            <button
              @click="search()"
              class="btn bg-skillper-orange mt-4 w-100 text-white"
            >
              Buscar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row w-100 mb-4">
      <p class="mb-0">Filtrar por Productor</p>
      <div class="col-12 col-md-4">
        <select
          v-model="selectedProducer"
          @change="filterByProducer"
          class="form-select"
        >
          <option value="">Todos los Productores</option>
          <option
            v-for="producer in productStore.producers"
            :key="producer.auth0_id"
            :value="producer.auth0_id"
          >
            {{ producer.name }}
          </option>
        </select>
      </div>
    </div>

    <!-- Error State -->
    <div v-if="productStore.isError" class="alert alert-danger" role="alert">
      Error al cargar los productos. Por favor, intente nuevamente.
    </div>

    <!-- Loading and Content States -->
    <div class="row">
      <template v-if="productStore.isLoading">
        <Skeleton v-for="index in 4" :key="index" class="col-md-6" />
      </template>
      
      <p
        v-else-if="productStore.listProducts.length === 0"
        class="col-12 text-center"
      >
        No hay productos Agregados...
      </p>

      <template v-else>
        <div
          class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4 card-product"
          v-for="(item, index) in productStore.listProducts"
          :key="index"
        >
          <CardProduct
            @edit="editProduct"
            @delete="deleteProduct"
            @affiliates="getAffiliates"
            :product="item"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { useProductStore } from "@/stores/productStore";
import router from "@/router";
import TextField from "@/components/common/TextField.vue";
import CardProduct from "@/components/products/CardProduct.vue";
import Skeleton from "@/components/skeleton/CardSkeleton.vue";

const productStore = useProductStore();
const productName = ref(null);
const selectedProducer = ref("");

const deleteProduct = async (_id) => {
  const success = await productStore.deleteProduct(_id);
  if (success) {
    await productStore.getAllProducts();
  }
};

const editProduct = async (_id) => {
  await productStore.getProduct(_id);
  router.push({ name: "editProduct" });
};

const getAffiliates = async (_id) => {
  router.push({ name: "affiliates", params: { id: _id } });
};

const filterByProducer = async () => {
  if (selectedProducer.value) {
    await productStore.getProductsByProducer(selectedProducer.value);
  } else {
    await productStore.getAllProducts();
  }
};

const search = async () => {
  if (productName.value?.trim()) {
    await productStore.getProductSearch(productName.value);
  } else {
    await productStore.getAllProducts();
  }
};

onMounted(async () => {
  try {
    await productStore.getAllProducts();
    productStore.resetCurrentProduct();
  } catch (error) {
    console.error("Error in component mount:", error);
  }
});

watch(productName, async (newValue) => {
  if (newValue === "") {
    await productStore.getAllProducts();
    productStore.resetCurrentProduct();
  }
});
</script>