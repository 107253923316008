import { ref } from 'vue';
import axios from '@/plugins/axios';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/User';

export const useAuth = () => {
  const router = useRouter();
  const isLoading = ref(false);
  const error = ref(null);
  const userStore = useUserStore();
  

  const refreshToken = async () => {
    try {
      console.log('[Auth] Attempting to refresh token');
      await axios.post(`/api/auth0-refresh-token/`);
      await userStore.getUserInfo();
      console.log('[Auth] Token refresh successful');
      return true;
    } catch (err) {
      console.error('[Auth] Token refresh error:', err);
      userStore.clearUserInfo();
      return false;
    }
  };

  const handleCustomLogin = async (email, password) => {
    console.log('[Auth] Login attempt started');
    isLoading.value = true;
    error.value = null;
  
    try {
      console.log('[Auth] Sending login request');
      await axios.post(`/api/auth0-login/`, {
        email,
        password,
      });
      
      await userStore.getUserInfo();
      const redirectPath = sessionStorage.getItem('redirectPath') || '/';
      await router.push(redirectPath);
      return true;
    } catch (err) {
      console.error('[Auth] Login error:', err);
      error.value = err.response?.data?.message || 'Se ha producido un error durante el inicio de sesión';
      return false;
    } finally {
      isLoading.value = false;
    }
  };

  const handleLogout = async () => {
    console.log('[Auth] Logout initiated');
    try {
      // Clear all store data
      userStore.clearUserInfo();
      // Add other store clearing calls here if needed
      
      await axios.post(`/api/logout/`);
      await router.push('/login');
    } catch (error) {
      console.error('[Auth] Logout error:', error);
      window.location.href = '/login';
    }
  };

  return {
    isLoading,
    error,
    handleCustomLogin,
    handleLogout,
    refreshToken
  };
};