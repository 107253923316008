import axios from 'axios';
import router from '@/router';
import { useUserStore } from '@/stores/User';

// Set default config for all axios requests
axios.defaults.withCredentials = true;

// Create axios instance with default config
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true, // Enable credentials for the instance
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

// Set withCredentials for all requests made with this instance
axiosInstance.defaults.withCredentials = true;

// Response interceptor
axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      const userStore = useUserStore();
      userStore.clearUserInfo();
      
      if (router.currentRoute.value.path !== '/login') {
        await router.push('/login');
      }
    }

    // Handle server errors
    if (error.response?.status === 500) {
      await router.push('/500');
    }

    // Handle not found errors
    if (error.response?.status === 404) {
      await router.push('/404');
    }
    
    return Promise.reject(error);
  }
);

export default axiosInstance;