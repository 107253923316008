import { createRouter, createWebHistory } from "vue-router"
import AccountStatus from "@/views/AccountStatus.vue"
import AffiliateProducts from "@/views/AffiliateProducts.vue"
import AffiliateSalesList from "@/views/AffiliateSalesList.vue"
import AffiliatesList from "@/views/AffiliatesList.vue"
import allProducts from "@/views/AllProducts.vue"
import Home from "@/views/HomeMain.vue"
import NotFound from "@/views/NotFound.vue"
import PayoutsList from "@/views/PayoutsList.vue"
import AddProduct from "@/views/Product/AddProduct.vue"
import EditProduct from "@/views/Product/EditProduct.vue"
import AppProfile from "@/views/Profile.vue"
import ReportsView from "@/views/ReportsView.vue"
import SalesList from "@/views/SalesList.vue"
import ServerError from "@/views/ServerError.vue"
import Login from "@/components/AdminLogin.vue"
import { useAuth } from "@/composables/useAuth"
import { useUserStore } from "@/stores/User"

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: "/",
    name: "dashboard-home",
    component: Home,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "home",
        component: AffiliateProducts,
        meta: { requiresAuth: true },
      },
      {
        path: "/products",
        name: "products",
        component: allProducts,
        meta: { requiresAuth: true },
      },
      {
        path: "/sales",
        name: "sales",
        component: SalesList,
        meta: { requiresAuth: true },
      },
      {
        path: "/affiliate-sales",
        name: "affiliate-sales",
        component: AffiliateProducts,
        meta: { requiresAuth: true },
      },
      {
        path: "/affiliate-products",
        name: "affiliate-products",
        component: AffiliateSalesList,
        meta: { requiresAuth: true },
      },
      {
        path: "/addProduct",
        name: "AddProduct",
        component: AddProduct,
        meta: { requiresAuth: true },
      },
      {
        path: "/edit-product",
        name: "editProduct",
        component: EditProduct,
        meta: { requiresAuth: true },
      },
      {
        path: "/profile",
        name: "profile",
        component: AppProfile,
        meta: { requiresAuth: true },
      },
      {
        path: "/affiliates/:id?",
        name: "affiliates",
        component: AffiliatesList,
        meta: { requiresAuth: true },
      },
      {
        path: "/payouts",
        name: "payouts",
        component: PayoutsList,
        meta: { requiresAuth: true },
      },
      {
        path: "/reports",
        name: "reports",
        component: ReportsView,
        meta: { requiresAuth: true },
      },
      {
        path: "/404",
        name: "NotFound",
        component: NotFound,
        meta: { requiresAuth: true }
      },
      {
        path: "/500",
        name: "ServerError",
        component: ServerError,
        meta: { requiresAuth: true }
      },
      {
        path: "/accountStatus",
        name: "accountStatus",
        component: AccountStatus,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log('[Router] Navigation started:', { to: to.path, from: from.path });
  const { refreshToken } = useAuth();
  
  // If going to login page
  if (to.path === '/login') {
    const userStore = useUserStore();
    if (userStore.userInfo) {
      console.log('[Router] Already authenticated, redirecting to home');
      return next('/');
    }
    return next();
  }

  // For all other routes that require auth
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log('[Router] Route requires auth');
    const userStore = useUserStore();
    
    if (!userStore.userInfo) {
      console.log('[Router] Not authenticated, redirecting to login');
      sessionStorage.setItem('redirectPath', to.fullPath);
      return next('/login');
    }

    try {
      console.log('[Router] Checking token status');
      const refreshResult = await refreshToken();
      
      if (!refreshResult) {
        console.log('[Router] Token refresh failed, redirecting to login');
        return next('/login');
      }
      
      console.log('[Router] Auth check passed, proceeding with navigation');
      return next();
    } catch (error) {
      console.error('[Router] Auth error:', error);
      return next('/login');
    }
  }

  console.log('[Router] No auth required, proceeding with navigation');
  next();
});

export default router;