<template>
  <div class="content">
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <TextField
          id="productTitle"
          v-model="currentProduct.title"
          :errorMessage="
            handlerValidate?.['title']?.$errors?.length > 0
              ? 'Este campo es requerido'
              : undefined
          "
          name="productTitle"
          :label="'Título del Producto'"
        />
      </div>

      <div class="form-group">
        <label for="productDescription">Descripción del producto</label>
        <textarea
          :class="
            handlerValidate?.['description']?.$errors?.length > 0
              ? 'form-error-input'
              : ''
          "
          class="form-control"
          id="productDescription"
          v-model="currentProduct.description"
          rows="3"
        ></textarea>
        <div class="textField">
          <p
            class=" "
            v-if="handlerValidate?.['description']?.$errors?.length > 0"
            v-text="'Este campo es requerido'"
          />
        </div>
      </div>
      <div class="form-group">
        <!-- <label for="productPrice">Precio del Producto (USD)</label>

        <input
          name="productPrice"
          id="productPrice"
          class="form-control"
          type="text"
          @input="validateInput"
          v-model="currentProduct.price"
        />
        <div class="textField">
          <p
            class=" "
            v-if="handlerValidate?.['price']?.$errors?.length > 0"
            v-text="getPriceErrorMessage()"
          />
        </div> -->

        <TextField
          type="number"
          id="productPrice"
          v-model="currentProduct.price"
          :errorMessage="getPriceErrorMessage()"
          name="productPrice"
          :label="'Precio del Producto (USD)'"
        />
      </div>
      <div class="form-group">
        <label class="mb-2" for="productCategory">Categoría del Producto</label>
        <div class="row gap-3 check-form">
          <CheckBoxInput
            class="gap-1 col-2"
            v-for="(category, index) in categories"
            :key="category.id"
            :value="category.id"
            :for="`${index}`"
            :text="category.name"
            @change="checkOptionUpdate(category.id)"
          />
        </div>
      </div>

      <div class="form-group pt-4 pb-2">
        <label class="style-check font-medium text-tertiary">
          <input type="checkbox" v-model="susc" />
          <span class="checkmark checkmark__blue" />
          <span v-text="'Producto de  suscripción '" />
        </label>
      </div>

      <div class="form-group">
        <label for="url">Url del curso</label>
        <input
          type="url"
          name="url"
          class="form-control"
          v-model="currentProduct.external_url"
          id="url"
          placeholder="https://example.com"
          pattern="https://.*"
          required
          @invalid="showError = true"
          @input="showError = false"
        />

        <div class="textField">
          <p
            v-if="showError"
            class="error-message"
            v-text="'Por favor, ingresa una URL válida.'"
          />
         
        </div>
      </div>

      <!-- Portrait Image Upload -->
      <div class="form-group">
        <label class="mb-2" for="productPortrait">Imagen del producto</label
        ><br />
        <input
          type="file"
          class="form-control-file"
          id="productPortrait"
          @change="uploadPortraitImage($event)"
          accept="image/*"
        />
      </div>

      <div id="modulesContainer">
        <h4 class="mt-4">Módulos</h4>
        <div
          class="card"
          v-for="(module, moduleIndex) in currentProduct.modules"
          :key="moduleIndex"
          :id="'module' + (moduleIndex + 1)"
        >
          <div class="card-body">
            <h5 class="card-title">Módulo {{ moduleIndex + 1 }}</h5>
            <div class="form-group">
              <label :for="'moduleTitle' + (moduleIndex + 1)"
                >Título del Módulo</label
              >
              <input
                type="text"
                class="form-control"
                :id="'moduleTitle' + (moduleIndex + 1)"
                v-model="module.title"
                required
              />
            </div>
            <div :id="'lessonsContainer' + (moduleIndex + 1)">
              <h6>Lecciones</h6>
              <div
                class="card mb-4"
                v-for="(lesson, lessonIndex) in module.lessons"
                :key="lessonIndex"
                :id="'lesson' + (moduleIndex + 1) + '_' + (lessonIndex + 1)"
              >
                <div class="card-body">
                  <h6 class="card-title">Lección {{ lessonIndex + 1 }}</h6>
                  <div class="form-group">
                    <label
                      :for="
                        'lessonTitle' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      >Título de la lección</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      :id="
                        'lessonTitle' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      v-model="lesson.title"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label
                      :for="
                        'lessonContent' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      >Contenido de la lección</label
                    >
                    <textarea
                      class="form-control"
                      :id="
                        'lessonContent' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      v-model="lesson.content"
                      rows="3"
                      required
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label
                      class="mb-2"
                      :for="
                        'lessonVideo' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      >Subi Media</label
                    ><br />
                    <input
                      type="file"
                      class="form-control-file"
                      :id="
                        'lessonVideo' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      @change="uploadVideo($event, moduleIndex, lessonIndex)"
                    />
                  </div>
                  <button
                    type="button"
                    class="btn btn-danger"
                    @click="removeLesson(moduleIndex, lessonIndex)"
                  >
                    Eliminar Lección
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-secondary mt-2 mx-2"
              @click="addLesson(moduleIndex)"
            >
              Añadir Lección
            </button>

            <button
              type="button"
              class="btn btn-danger mt-2"
              @click="removeModule(moduleIndex)"
            >
              Remove Module
            </button>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn btn-secondary mt-4 mx-2"
        @click="addModule"
      >
        Agregar Modulo
      </button>
      <button type="submit" class="btn btn-primary mt-4">Subir</button>
    </form>
  </div>
</template>

<script>
import { showNotification } from "@/composables/useNotification"
import router from "@/router"
import useVuelidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import axios from "axios"
import { onMounted, ref } from "vue"
import { useProductStore } from "../stores/productStore"
import CheckBoxInput from "./common/CheckInput.vue"
import TextField from "./common/TextField.vue"

export default {
  name: "ProductForm",
  components: {
    TextField,
    CheckBoxInput,
  },
  setup() {
    const store = useProductStore();
    const currentProduct = ref(store.currentProduct);
    const categories = ref([]);
    const susc = ref(false);
    const showError = ref(false);
    const handlerValidate = useVuelidate(
      {
        title: {
          required,
        },
        description: {
          required,
        },
        price: {
          required,
        },
      },
      currentProduct.value
    );

    const fetchAndSetCategories = async () => {
      await store.fetchCategories();
      categories.value = store.categories;
    };

    const checkOptionUpdate = (event) => {
      const index = currentProduct.value.categories.indexOf(event);
      if (index !== -1) {
        currentProduct.value.categories.splice(index, 1);
      } else {
        currentProduct.value.categories.push(event);
      }
    };

    // Module and lesson management functions remain the same
    const addModule = () => store.addModule();
    const removeModule = (moduleIndex) => store.removeModule(moduleIndex);
    const addLesson = (moduleIndex) => store.addLesson(moduleIndex);
    const removeLesson = (moduleIndex, lessonIndex) =>
      store.removeLesson(moduleIndex, lessonIndex);
    
    const uploadPortraitImage = (event) => {
      const file = event.target.files[0];
      currentProduct.value.portraitImage = file;
    };

    const uploadVideo = async (event, moduleIndex, lessonIndex) => {
      const file = event.target.files[0];
      currentProduct.value.modules[moduleIndex].lessons[lessonIndex].videoFile = file;
    };

    const submitForm = async () => {
      const submitButton = document.getElementsByClassName("btn btn-primary mt-4")[0];
      const _validate = await handlerValidate.value.$validate();
      if (!_validate) return;
      
      submitButton.classList.add("disabled");
      const formData = new FormData();
      
      // Append all form data
      formData.append("title", currentProduct.value.title);
      formData.append("description", currentProduct.value.description);  
      formData.append("price", currentProduct.value.price);
      formData.append("categories", JSON.stringify(currentProduct.value.categories));
      formData.append("is_sub", susc.value);
      formData.append("external_url", currentProduct.value.external_url);

      if (currentProduct.value.portraitImage) {
        formData.append("portraitImage", currentProduct.value.portraitImage);
      }

      const modulesData = currentProduct.value.modules.map((module) => ({
        title: module.title,
        lessons: module.lessons.map((lesson) => ({
          title: lesson.title,
          content: lesson.content,
        })),
      }));

      formData.append("modules", JSON.stringify(modulesData));

      // Append video files
      currentProduct.value.modules.forEach((module) => {
        module.lessons.forEach((lesson) => {
          if (lesson.videoFile) {
            formData.append(`file_${module.title}_${lesson.title}`, lesson.videoFile);
          }
        });
      });

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/products/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 201) {
          showNotification("Producto creado", "success");
          setTimeout(() => {
            router.push({ name: "products" });
          }, 1500);
        }
      } catch (error) {
        showNotification("Algo ha ido mal", "error");
        console.error("Error creating product:", error);
      }
      
      setTimeout(() => {
        submitButton.classList.remove("disabled");
      }, 1600);
    };

    const getPriceErrorMessage = () => {
      const errors = handlerValidate.value.price.$errors;
      if (errors.length > 0) {
        if (errors[0].$validator === "required") {
          return "Este campo es requerido";
        }
      }
      return undefined;
    };

    onMounted(() => {
      fetchAndSetCategories();
    });

    return {
      currentProduct,
      categories,
      addModule,
      removeModule,
      addLesson,
      removeLesson,
      uploadVideo,
      submitForm,
      uploadPortraitImage,
      handlerValidate,
      checkOptionUpdate,
      getPriceErrorMessage,
      susc,
      showError,
    };
  },
};
</script>

<style scoped>
.content {
  /* margin-left: 240px; */
  /* padding: 20px; */
}
.form-group {
  margin-bottom: 20px;
}
label {
  font-weight: 500;
}
.textField {
  position: relative;
  width: 100%;
}
.textField p {
  color: red;
  position: absolute;
  font-size: 12px;
  margin-top: 1px;
}
.textField span {
  font-weight: 500;
}
.form-error-input {
  border: 1px solid red;
}
</style>
