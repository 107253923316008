<template>
  <section>
    <div class="mb-4">
      <div class="main-title">
        <h2 class="fw-semibold">{{ $t("MENU.AFFILIATE_PRODUCTS") }}</h2>
      </div>
    </div>

    <div v-if="isLoading" class="text-center">
      <p>Cargando productos...</p>
    </div>

    <div v-else-if="error" class="alert alert-danger" role="alert">
      {{ error }}
    </div>

    <DataTable 
      v-else-if="filteredProducts.length > 0" 
      :headers="productTableHeaders"
    >
      <template #body>
        <tr v-for="(product, index) in filteredProducts" :key="index">
          <td class="p-3" v-text="product.product.title || '-'" />
          <td class="p-3" v-text="product.percentage + '%' || '-'" />
          <td class="p-3" v-text="formatPrice(product.product.price) || '-'" />
          <td class="p-3" v-text="product.product.categories[0] || '-'" />
          <td class="p-3" v-text="product.product.producer || '-'" />
          <td class="p-3" v-text="product.status || '-'" />
          <td class="p-3">
            <button 
              class="btn bg-green text-white" 
              @click="copyLink(product.product.id, product.affiliate.utm)"
            >
              {{ copyStatus === product.product.id ? 'Copiado!' : 'Copiar link' }}
            </button>
          </td>
        </tr>
      </template>
    </DataTable>

    <div v-else class="text-center">
      <p>No se encontraron productos</p>
    </div>
  </section>
</template>

<script setup>
import DataTable from "@/components/common/DataTable.vue"
import { computed, onMounted, ref, watch } from "vue"
import { useAuth } from "@/composables/useAuth"
import { useUserStore } from "@/stores/User"
import { showNotification } from "@/composables/useNotification"

const formStore = useAuth()
const userStore = useUserStore()
const baseURL = process.env.VUE_APP_MARKET_URL

const isLoading = ref(false)
const error = ref(null)
const copyStatus = ref(null)
const productSearch = ref('')
const currentPage = ref(1)

const productTableHeaders = computed(() => [
  {
    width: "w-3/12",
    title: "Nombre del producto",
  },
  {
    width: "w-4/12",
    title: "Comisión",
  },
  {
    width: "w-1/12",
    title: "Precio",
  },
  {
    width: "w-2/12",
    title: "Categoría",
  },
  {
    width: "w-2/12",
    title: "Productor",
  },
  {
    width: "w-2/12",
    title: "Estado",
  },
  {
    width: "w-2/12",
    title: "Link de Afiliado",
  },
])

const affiliateProducts = computed(() => userStore.affiliateProducts)

const filteredProducts = computed(() => {
  return affiliateProducts.value.filter(product =>
    product.product.title.toLowerCase().includes(productSearch.value.toLowerCase())
  )
})

async function fetchAffiliateProducts() {
  try {
    isLoading.value = true
    error.value = null
    await userStore.getAffiliateProducts()
  } catch (err) {
    console.error('Error fetching affiliate products:', err)
    error.value = 'Error al cargar los productos'
  } finally {
    isLoading.value = false
  }
}

function formatPrice(price) {
  if (!price) return '-'
  return `$${parseFloat(price).toFixed(2)}`
}

async function copyLink(productId, utm) {
  const link = `${baseURL}/products/detail/${productId}?utm_affiliate=${utm}`
  try {
    await navigator.clipboard.writeText(link)
    copyStatus.value = productId
    showNotification('Link copiado al portapapeles', 'success')
    setTimeout(() => {
      if (copyStatus.value === productId) {
        copyStatus.value = null
      }
    }, 2000)
  } catch (err) {
    console.error('Failed to copy link:', err)
    showNotification('Error al copiar el link', 'error')
  }
}

// Initial fetch on mount if authenticated
onMounted(async () => {
  if (formStore.token) {
    await fetchAffiliateProducts()
  }
})

// Watch for auth token changes
watch(() => formStore.token, async (newToken) => {
  if (newToken) {
    await fetchAffiliateProducts()
  }
}, { immediate: true })

// Watch for page changes
watch(currentPage, fetchAffiliateProducts)
</script>

<style scoped>
.bg-green {
  background-color: #28a745;
}

.bg-green:hover {
  background-color: #218838;
}

.alert {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>