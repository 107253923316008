import axios from "axios";
import { defineStore } from "pinia";

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    // User store states
    _status: null,
    _error: null,
    _users: null,
    _userInfo: null,

    // Producer store states
    _user: JSON.parse(sessionStorage.getItem("profile")) || null,
    _company: JSON.parse(sessionStorage.getItem("company")) || null,
    _profile: null,
    _producer: null,
    _isProd: false,
    _verifiedUser: false,

    // Affiliate store states
    _affiliate: null,
    _affiliateProducts: [],
    _totalProducts: 0,
    _isAffiliate: false,
  }),
  getters: {
    // User store getters
    isLoading: (state) => state._status === "loading",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    token: (state) => state._token,
    users: (state) => state._users,
    userInfo: (state) => state._userInfo,

    // Producer store getters
    user: (state) => state._user,
    company: (state) => state._company,
    profile: (state) => state._profile,
    producer: (state) => state._producer,
    isProd: (state) => state._isProd,
    verifiedUser: (state) => state._verifiedUser,

    // Affiliate store getters
    isLoadingAll: (state) => state._status === "loadingAll",
    isReadyAll: (state) => state._status === "readyAll",
    affiliate: (state) => state._affiliate,
    affiliateProducts: (state) => state._affiliateProducts,
    totalProducts: (state) => state._totalProducts,
    isAffiliate: (state) => state._isAffiliate,
  },
  actions: {
    // User store actions
    async getUserInfo() {
      this.changeStatus("loading");
      axios.defaults.withCredentials = true;
    
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/userinfo/`,
        );

        this._userInfo = {
          sub: data.user_id,
          email: data.email,
          name: data.name,
          picture: data.picture,
          email_verified: data.email_verified,
          given_name: data.given_name,
          family_name: data.family_name,
          nickname: data.nickname
        };

        this.changeStatus("ready");
        return this._userInfo;
      } catch (error) {
        console.error('Error fetching user info:', error);
        this._userInfo = null;
        this.changeStatus("error", error);
        throw error;
      }
    },

    clearUserInfo() {
      this._userInfo = null;
    },

    async getUsers() {
      this.changeStatus("loading");
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/user-list/`
        );

        this._users = response.data
          .filter(e => e?.first_name != undefined && e?.last_name != undefined)
          .map(e => {
            const { id, username, email } = e;
            const full_name = `${e.first_name} ${e.last_name}`.trim();
            return full_name !== "" ? { id, username, email, full_name } : null;
          })
          .filter(Boolean);

        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error", error);
        throw error;
      }
    },

    // Producer store actions
    async getUserProfile(auth0Id) {
      this.changeStatus("loading");
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/profiles/${auth0Id}/`,
        );
        console.log(response);
        this._profile = response.data;
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching profile:", error);
      }
    },

    async saveProfile(payload) {
      this.changeStatus("loading");
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/producer-profile-update/`,
          payload,
        );
        console.log(response);
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching profile:", error);
      }
    },

    async getProducer(auth0Id) {
      this.changeStatus("loading");
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/producers/${auth0Id}/`,
        );
        console.log(response);
        this._producer = response.data;
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching profile:", error);
      }
    },

    async verifyProducer(auth0Id) {
      this.changeStatus("loading");
      this._verifiedUser = 'loading'
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/producers/${auth0Id}/`,
        );
        if (response.status == 200){
          this._verifiedUser = 'verified'
        }
        this.changeStatus("ready");
      } catch (error) {
        console.log(error);
        this._verifiedUser = 'error'
        this.changeStatus("error");
      }
    },

    // Affiliate store actions
    async getAffiliates(user) {
      this.changeStatus("loading");

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/affiliate-products/?affiliate__user__auth0_id=${user}`
        );
        this._affiliate = response.data.results;
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching list:", error);
      }
    },

    async getAffiliateProducts() {
      this.changeStatus("loading");
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/affiliate-products/`,
        );
        
        console.log(response.data.results);
        this._affiliateProducts = response.data.results;
        this._totalProducts = response.data.count || 0;
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching affiliate products:", error);
      }
    },

    async verifyAffiliate() {
      this.changeStatus("loading");
      this._verifiedUser = 'loading'
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/affiliates/`,
        );
        if (response.status == 200){
          this._verifiedUser = 'verified'
        }
        this.changeStatus("ready");
      } catch (error) {
        console.log(error);
        this._verifiedUser = 'error'
        this.changeStatus("error");
      }
    },

    // Common action used across all stores
    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
  },
});