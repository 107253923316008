<script setup>
import DataTable from "@/components/common/DataTable.vue"
import TextField from "@/components/common/TextField.vue"
import ModalSales from "@/components/ModalSales.vue"
import { useSalesStore } from "@/stores/Sales"
import { BPagination } from "bootstrap-vue-next"
import { computed, onMounted, ref, watch } from "vue"
import "vue3-toastify/dist/index.css"

const salesStore = useSalesStore();
const showModalDetail = ref(false);

const titlesTable = computed(() => [
  {
    width: "w-4/12",
    title: "Producto",
  },
  {
    width: "w-4/12",
    title: "Nombre y apellido",
  },
  {
    width: "w-1/6",
    title: "Email",
  },
  {
    width: "w-4/12",
    title: "Fecha",
  },
  {
    width: "w-4/12",
    title: "Estado",
  },
  {
    width: "w-4/12",
    title: "",
  },
]);

onMounted(async () => {
  try {
    await salesStore.getSales();
    await salesStore.getStatus();
  } catch (error) {
    console.error('Error loading initial data:', error);
  }
});

function formatDate(dateString) {
  const date = new Date(dateString);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

const product = ref(null);
const dateFrom = ref(null);
const dateTo = ref(null);
const state = ref(null);

const search = async () => {
  try {
    await salesStore.getSales(
      product.value,
      dateFrom.value,
      dateTo.value,
      1,
      state.value
    );
  } catch (error) {
    console.error('Error during search:', error);
  }
};

const pages = computed(() => {
  return Math.ceil(salesStore.pageCount);
});

// Watch for page changes
watch(() => salesStore.page, async (newPage, oldPage) => {
  if (oldPage !== 0 && newPage !== 0) {
    try {
      await salesStore.getSales(
        product.value,
        dateFrom.value,
        dateTo.value,
        newPage,
        state.value
      );
    } catch (error) {
      console.error('Error during page change:', error);
    }
  }
});

const modalDetail = async (id) => {
  try {
    await salesStore.getSale(id);
    showModalDetail.value = true;
  } catch (error) {
    console.error('Error loading sale detail:', error);
  }
};

const formattedNumber = computed(() => {
  if (!salesStore.total) return '0';
  const numStr = salesStore.total.toString();
  const [integerPart, decimalPart] = numStr.split('.');
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return formattedInteger + (decimalPart ? '.' + decimalPart : '');
});

const isProducer = process.env.VUE_APP_APP_TYPE === 'skillper';

// Watch for store status changes
watch(() => salesStore.isError, (hasError) => {
  if (hasError) {
    console.error('Error in sales store:', salesStore.error);
  }
});

// Watch for sales data changes
watch(() => salesStore.sales, (newSales) => {
  if (!newSales?.results?.length && salesStore.isReady) {
    salesStore.refreshCurrentSearch();
  }
}, { deep: true });
</script>

<template>
  <section>
    <div class="mb-20">
      <p class="title-text">Monto recaudado</p>
      <div class="row gap-5 ms-1">
        <div v-if="isProducer" class="col-md-2 rounded p-3 border rounded-lg">
          <p class="fs-5 fw-bold">$ {{ formattedNumber }}</p>
          <p class="pb-0 mb-0 fw-medium">Total</p>
        </div>
        <div class="col-md-2 rounded p-3 border rounded-lg">
          <p class="fw-bold fs-5">$ {{ salesStore.totalAmountProducer }}</p>
          <p class="pb-0 mb-0 fw-medium">Por retirar</p>
        </div>
        <div class="col-md-2 rounded p-3 border rounded-lg">
          <p class="fw-bold fs-5">$ {{ salesStore.sales?.total_amount_refunded }}</p>
          <p class="pb-0 mb-0 fw-medium">Reembolsado</p>
        </div>
      </div>
    </div>

    <section class="mb-4">
      <div class="main-title">
        <h2 class="fw-semibold">{{ $t("MENU.SALES") }}</h2>
      </div>
      <div class="d-flex w-100 gap-4">
        <div class="row w-100">
          <div class="col-2">
            <TextField
              v-model="product"
              id="search"
              type="text"
              name="productPrice"
              :label="'Producto'"
              placeholder="Buscar "
            />
          </div>
          <div class="col-2">
            <TextField
              v-model="dateFrom"
              id="dateFrom"
              type="date"
              name="dateFrom"
              label="Desde"
              placeholder="From "
            />
          </div>
          <div class="col-2">
            <TextField
              v-model="dateTo"
              id="dateTo"
              type="date"
              name="dateTo"
              label="Hasta"
              placeholder="To "
            />
          </div>
          <div class="col-3">
            <div class="d-flex gap-4 justify-content-between items-center">
              <div class="w-100">
                <p v-text="'Estado'" class="mb-0" />
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="state"
                >
                  <option value="" selected>Seleccionar</option>
                  <option
                    v-for="(status, index) in salesStore.statusSales"
                    :key="index"
                    :value="status.id"
                  >
                    {{ status.value }}
                  </option>
                </select>
              </div>
              <div class="w-30">
                <button
                  @click="search()"
                  class="btn bg-skillper-orange mt-4 w-100 text-white"
                  :disabled="salesStore.isLoading"
                >
                  {{ salesStore.isLoading ? 'Cargando...' : 'Buscar' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <DataTable :noHaveData="!salesStore.sales?.results?.length" :headers="titlesTable">
      <template #body>
        <tr v-for="(item, index) in salesStore.sales?.results" :key="index">
          <td class="p-3" v-text="item.product.title || '-'" />
          <td class="p-3" v-text="item.user.username || '-'" />
          <td class="p-3" v-text="item.user.email || '-'" />
          <td class="p-3" v-text="formatDate(item.created_at) || '-'" />
          <td class="p-3" v-text="item.status?.value || '-'" />
          <td class="p-3">
            <p
              class="color-skillper-orange pb-0 mb-0 underline"
              style="cursor: pointer"
              @click="modalDetail(item.id)"
            >
              Ver detalle
            </p>
          </td>
        </tr>
      </template>
    </DataTable>
    <BPagination
      v-model="salesStore.page"
      :total-rows="pages"
      :per-page="10"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    />
  </section>
  <Teleport to="body">
    <ModalSales
      @close="showModalDetail = false"
      v-if="showModalDetail"
      :sales="salesStore.sale"
    />
  </Teleport>
</template>