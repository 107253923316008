<script setup>
import DataTable from "@/components/common/DataTable.vue";
import TextField from "@/components/common/TextField.vue";
import ModalSales from "@/components/ModalSales.vue";
import PaginationControl from "@/components/common/PaginationControl.vue";
import { useAuth } from "@/composables/useAuth"
import { useSalesStore } from "@/stores/Sales";
import { computed, onMounted, ref } from "vue";

const storeForm = useAuth();
const salesStore = useSalesStore();
const currentPage = ref(1);

const data = ref([
  {
    product: "Product 1",
    name: "Hugo perez",
    email: "hugo@hugo",
    phone: "123456789",
    date: "2022-01-01",
  },
  {
    product: "Product 2",
    name: "Test perez",
    email: "hugo@hugo",
    phone: "123456789",
    date: "2022-01-01",
  },
]);

const titlesTable = computed(() => [
  {
    width: "w-4/12",
    title: "Total vendido",
  },
  {
    width: "w-4/12",
    title: "Total productor",
  },
  {
    width: "w-4/12",
    title: "Monto a abonar",
  },
  {
    width: "w-2/12",
    title: "",
  },
]);

onMounted(async () => {
  if (storeForm.token) {
    await salesStore.getSales();
    await salesStore.getStatus();
  }
});

const handlePageChange = async (page) => {
  currentPage.value = page;
  await salesStore.getSales(
    product.value,
    dateFrom.value,
    dateTo.value,
    page,
    state.value,
    producerName.value
  );
};

const product = ref(null);
const dateFrom = ref(null);
const dateTo = ref(null);
const state = ref(null);
const producerName = ref(null);

const search = async () => {
  currentPage.value = 1; // Reset to first page when searching
  await salesStore.getSales(
    product.value,
    dateFrom.value,
    dateTo.value,
    1,
    state.value,
    producerName.value
  );
};

// Remove the watch since we're handling pagination differently now
</script>

<template>
  <section>
    <section class="mb-4">
      <div class="main-title">
        <h2 class="fw-semibold">Estado de Cuenta</h2>
      </div>
      <div class="d-flex w-100 gap-4">
        <div class="row w-100">
          <!-- <div class="col-2">
            <label
              class="w-full font-medium text-background-dark textField"
              for="productCategory"
              >Persona</label
            > 
            <select
              class="form-control"
              id="productCategory"
              v-model="account_type"
              required
            >
              <option value="" disabled selected>Seleccionar</option>
              <option v-for="item in data" :key="item" :value="item">
                {{ item.name }}
              </option>
            </select>
          </div>-->

          <div class="col-2">
            <TextField
              v-model="dateFrom"
              id="dateFrom"
              type="date"
              name="dateFrom"
              label="Desde"
              placeholder="From "
            />
          </div>
          <div class="col-2">
            <TextField
              v-model="dateTo"
              id="dateTo"
              type="date"
              name="dateTo"
              label="Hasta"
              placeholder="To "
            />
          </div>
          <div class="col-2">
            <div class="d-flex gap-4 justify-content-between items-center">
              <div class="w-30">
                <button
                  @click="search()"
                  class="btn bg-skillper-orange mt-4 w-100 text-white"
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <DataTable :noHaveData="data?.length === 0" :headers="titlesTable">
      <template #body>
        <tr v-for="(item, index) in salesStore.sales?.results" :key="index">
          <td class="p-3" v-text="'$' + item.total || '0'" />
          <td class="p-3" v-text="'$' + item.total_producer || '0'" />
          <td class="p-3" v-text="'-'" />
          <td class="p-3">
            <p
              class="color-skillper-orange pb-0 mb-0 underline"
              style="cursor: pointer"
            >
              Realizar pago
            </p>
          </td>
        </tr>
      </template>
    </DataTable>

    <PaginationControl
      v-if="salesStore.sales?.results?.length"
      :current-page="currentPage"
      :total-pages="salesStore.pageCount"
      @page-change="handlePageChange"
    />

    <Teleport to="body">
      <ModalSales
        @close="showModalDetail = false"
        v-if="showModalDetail"
        :sales="salesStore.sale"
      />
    </Teleport>
  </section>
</template>

<style scoped>
.refund {
  background: #9c9c9c !important;
}
</style>