import axios from "axios"
import { defineStore } from "pinia"
import { useCoreStore } from './core'
import { toast } from "vue3-toastify"

export const useSalesStore = defineStore("sales", {
  state: () => ({
    _status: null,
    _error: null,
    _sales: null,
    _total: 0,
    _pageCount: 10,
    _page: 1,
    _statusSales: null,
    _sale: null,
    _totalAmountAffiliate: null,
    _totalAmountProducer: null
  }),
  getters: {
    isLoading: (state) => state._status === "loading",
    isLoadingAll: (state) => state._status === "loadingAll",
    isReadyAll: (state) => state._status === "readyAll",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    sales: (state) => state._sales,
    total: (state) => state._total,
    pageCount: (state) => state._pageCount,
    page: (state) => state._page,
    statusSales: (state) => state._statusSales,
    sale: (state) => state._sale,
    totalAmountAffiliate: (state) => state._totalAmountAffiliate,
    totalAmountProducer: (state) => state._totalAmountProducer
  },
  actions: {
    async getSales(
      product = undefined,
      dateFrom = undefined,
      dateTo = undefined,
      pageNumber = 1,
      state = undefined,
      producerName = undefined
    ) {
      let url = `${process.env.VUE_APP_API_URL}/api/sales/sales_summary/?type=producer&page=${pageNumber}`;
      
      this.changeStatus("loading");

      if (product) {
        url += `&product_name=${product}`;
      }

      if (dateFrom) {
        url += `&date_from=${dateFrom}`;
      }

      if (dateTo) {
        url += `&date_to=${dateTo}`;
      }

      if (state) {
        url += `&status=${state}`;
      }
      if(producerName){
        url += `&producer_name=${producerName}`
      }

      try {
        const response = await axios.get(url, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this._pageCount = Math.ceil(response.data.count/10);
        this._sales = response.data;
        this._total = response.data.total_amount;
        this._totalAmountAffiliate = response.data.total_amount_affiliate;
        this._totalAmountProducer = response.data.total_amount_producer;
        this._page = pageNumber;
        this.changeStatus("ready");
        return response.data;
      } catch (error) {
        this.changeStatus("error");
        toast.error("Error al cargar las ventas", {
          autoClose: 3000,
          position: toast.POSITION.TOP_RIGHT,
        });
        console.error("Error fetching list:", error);
        throw error;
      }
    },
    
    async getStatus() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/sale-status/`
        );
        this._statusSales = response.data.results;
      } catch (error) {
        toast.error("Error al cargar los estados", {
          autoClose: 3000,
          position: toast.POSITION.TOP_RIGHT,
        });
        console.error("Error fetching categories:", error);
      }
    },

    async getSale(id) {
      const coreStore = useCoreStore();
      const subdomain = coreStore.subdomain;

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/sales/${id}/?type=${subdomain === 'skillper' ? 'producer' : 'affiliate'}`,{
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this._sale = response.data;
      } catch (error) {
        toast.error("Error al cargar el detalle de la venta", {
          autoClose: 3000,
          position: toast.POSITION.TOP_RIGHT,
        });
        console.error("Error ", error);
      }
    },

    async refund(values) {
      try {
        this.changeStatus('loading');
        const { sale_id } = values;
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/refund/`, 
          { sale_id },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if(response.status === 201) {
          // Find the refunded sale in the current data
          if (this._sales?.results) {
            const refundedSaleId = parseInt(sale_id);
            const sale = this._sales.results.find(s => s.id === refundedSaleId);
            if (sale) {
              // Update the status to "Reembolsada"
              sale.status = { 
                id: 1, // ID for "Reembolsada" status
                value: "Reembolsada"
              };
            }
          }

          toast.success("Reembolso realizado con éxito", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          this.changeStatus('ready');
          return true;
        }
   
        this.changeStatus('ready');
        return false;
      } catch (error) {
        this.changeStatus('error');
        if (error.response?.status === 400) {
          toast.error(error.response?.data?.message || "Error al procesar el reembolso", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error al procesar el reembolso", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        throw error;
      }
    },

    async updateSaleStatus(saleId, newStatusId, searchParams) {
      try {
        this.changeStatus('loading');
        
        const response = await axios.patch(
          `${process.env.VUE_APP_API_URL}/api/sales/${saleId}/`, 
          {
            status: newStatusId 
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if(response.status === 200) {
          // Update the local state immediately
          if (this._sales?.results) {
            const sale = this._sales.results.find(s => s.id === saleId);
            if (sale) {
              sale.status = {
                id: newStatusId,
                value: this._statusSales.find(s => s.id === newStatusId)?.value || ''
              };
            }
          }

          toast.success("Estado actualizado con éxito", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          
          // Refresh the data
          await this.getSales(
            searchParams.product,
            searchParams.dateFrom,
            searchParams.dateTo,
            searchParams.page,
            searchParams.state,
            searchParams.producerName
          );
          
          this.changeStatus('ready');
          return true;
        }
        
        this.changeStatus('ready');
        return false;
      } catch (error) {
        this.changeStatus('error');
        if (error.response?.status === 400) {
          toast.error(error.response?.data?.message || "Error al actualizar el estado", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error al actualizar el estado", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        console.error("Error updating sale status:", error);
        return false;
      }
    },

    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
  },
});