<template>
  <div class="main-title">
    <h2>{{ $t("PROFILE.TITLE") }}</h2>
    <button
      v-if="!edit"
      @click="edit = !edit"
      class="btn btn-dark"
    >
      <span v-if="!edit"> {{ $t("PROFILE.EDIT") }} </span>
    </button>
    <button
      v-else
      class="btn btn-primary"
      @click="save"
    >
      <span>{{ $t("PROFILE.SAVE") }}</span>
    </button>
  </div>
  <section>
    <div class="bg-sections d-flex mb-2">
      <div class="profile">
        <button v-if="!userStore.userInfo" @click="login" class="btn btn-primary">
          {{ $t("PROFILE.LOGIN") }}
        </button>
        <div v-else>
          <div class="user-details">
            <img
              :src="userStore.user?.picture || '/default-profile.png'"
              alt="Profile Picture"
              class="profile-picture mb-3"
            />
            <h3>{{ userStore.user?.name || userStore.profile?.user?.email }}</h3>
            <p>{{ userStore.profile?.user?.email }}</p>
          </div>
          <button @click="logOut" class="btn mb-3 btn-secondary">
            {{ $t("PROFILE.LOGOUT") }}
          </button>
        </div>
      </div>
      <div class="mx-4 w-full">
        <p class="text-secondary" v-text="$t('PROFILE.INFO')"></p>
        <span class="">Contactenos si desea cambiar esta informacion</span>
        <ProfileForm :form-data="{
          given_name: userStore.user?.given_name,
          family_name: userStore.user?.family_name,
          email: userStore.profile?.user?.email,
          phone: userStore.profile?.user?.phone
        }" :edit="edit" />
      </div>
    </div>

    <div class="w-full">
      <CompanyForm :edit="edit"/>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UserProfile',
}
</script>

<script setup>
import CompanyForm from "@/components/forms/CompanyForm.vue"
import ProfileForm from "@/components/forms/ProfileForm.vue"
import { useUserStore } from "@/stores/User"
import { useAuth } from "@/composables/useAuth"
import { onMounted, ref } from "vue"
import { useRouter } from 'vue-router'

const router = useRouter()
const form = useAuth()
const edit = ref(false)
const userStore = useUserStore()

const login = () => {
  router.push('/login')
}

const logOut = () => {
  form.logout()
}

onMounted(async () => {
  const userInfo = userStore.userInfo
  const userId = userInfo.sub
  try {
    if (userId) {
      await userStore.getUserProfile(userId)
      await userStore.getProducer(userId)
    }
  } catch (error) {
    console.error('Error decoding token:', error)
    form.logout()
  }
})

const save = async () => {
  edit.value = false
  await userStore.saveProfile({
    company_name: userStore.producer?.company_name,
    entity_type: userStore.producer?.entity_type,
    registration_number: userStore.producer?.registration_number,
    tax_id_number: userStore.producer?.tax_id_number,
    vat_id_number: userStore.producer?.vat_id_number,

    city: userStore.profile?.city,
    address: userStore.profile?.address,
    country: userStore.profile?.country,
    state: userStore.profile?.state,
    zip_code: userStore.profile?.zip_code,
    apartment: userStore.profile?.apartment,
    payload_email: userStore.profile?.payload_email,
    account_type: userStore.profile?.account_type,

    request_type: 'producer',
  })
}
</script>
