<script setup>
import DataTable from "@/components/common/DataTable.vue";
import EditableRow from "@/components/common/EditableRow.vue";
import PaginationControl from "@/components/common/PaginationControl.vue";
import { showNotification } from "@/composables/useNotification";
import axios from "axios";
import { BFormSelect } from "bootstrap-vue-next";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useProductStore } from "@/stores/productStore";
import router from "@/router";

const route = useRoute();
const productStore = useProductStore();
const product = ref();

onMounted(async () => {
  await productStore.getAllProducts(false);
  if (route.params.id) {
    getAffiliates(route.params.id);
    product.value = route.params.id;
  }
});

const data = ref([]);
const loading = ref(false);
const currentPage = ref(1);
const totalItems = ref(0);

const getProductAffiliates = async (_id) => {
  router.push({ name: "affiliates", params: { id: _id } });
  currentPage.value = 1; // Reset to first page when changing products
  await getAffiliates(_id);
};

const getAffiliates = async (productId, page = 1) => {
  try {
    loading.value = true;
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/api/affiliate-products/?product=${productId}&page=${page}&request_type=producer`,
    );
    totalItems.value = response.data.count;
    data.value = response.data.results;
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const handlePageChange = async (page) => {
  currentPage.value = page;
  await getAffiliates(route.params.id, page);
};

const totalPages = computed(() => {
  return Math.ceil(totalItems.value / 10);
});

const updateAffiliateProduct = async (affiliateProduct, payload) => {
  try {
    await axios.patch(
      `${process.env.VUE_APP_API_URL}/api/affiliate-products/${affiliateProduct.id}/?request_type=producer`,
      payload,
    );
    showNotification("Actualizado correctamente", "success");
  } catch (error) {
    console.log(error);
  }
};

const titlesTable = computed(() => [
  {
    width: "w-4/12",
    title: "Nombre y apellido",
  },
  {
    width: "w-1/6",
    title: "Email",
  },
  {
    width: "w-1/2",
    title: "Teléfono",
  },
  {
    width: "w-4/12",
    title: "Comisión de producto",
  },
  {
    width: "w-4/12",
    title: "Estatus",
  },
]);

const status_choices = [
  { value: "pendiente", text: "Pendiente" },
  { value: "eliminado", text: "Eliminado" },
  { value: "confirmado", text: "Confirmado" },
];

const updateItem = async (item) => {
  const index = data.value.findIndex((obj) => obj.id === item.id);
  data.value[index] = item;
  await updateAffiliateProduct(item, { percentage: item.percentage });
};
</script>

<template>
  <section>
    <div class="main-title">
      <h2>{{ $t("PRODUCT.AFFILIATES") }}</h2>
    </div>
    <div class="">
      <p v-text="'Productos'" class="mb-0" />
      <select
        class="form-select"
        aria-label="Default select example"
        v-model="product"
        @change="getProductAffiliates(product)"
      >
        <option value="" selected>Seleccionar</option>
        <option
          v-for="(obj, index) in productStore.listProducts"
          :key="index"
          :value="obj?.id"
        >
          {{ obj?.title }}
        </option>
      </select>
    </div>
    
    <DataTable
      :noHaveData="data?.length === 0"
      :headers="titlesTable"
      :isLoading="loading"
    >
      <template #body>
        <tr v-for="(item, index) in data" :key="index">
          <td
            class="p-3"
            v-text="
              item.affiliate?.user?.first_name +
              ' ' +
              item.affiliate?.user?.last_name
            "
          />
          <td class="p-3" v-text="item.affiliate.user.email || '-'" />
          <td class="p-3" v-text="item.affiliate.user.phone || '-'" />
          <td class="p-3">
            <EditableRow :item="item" @update-item="updateItem" />
          </td>
          <td class="p-3">
            <BFormSelect
              v-model="item.status"
              :options="status_choices"
              @change="updateAffiliateProduct(item, { status: item.status })"
            />
          </td>
        </tr>
      </template>
    </DataTable>

    <!-- Replace BPagination with PaginationControl -->
    <PaginationControl
      v-if="data.length > 0"
      :current-page="currentPage"
      :total-pages="totalPages"
      @page-change="handlePageChange"
    />
  </section>
</template>